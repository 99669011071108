<template>
    <Modal :contains-floating-button="true">
        <h1>Tilføj udgift</h1>
        <Form @submit="validatePayment">
            <fieldset>
                <!-- TODO: Tjek op på hvorfor budgetpost ikke selector ved første 'select' og man trykker ud -->
                <Select :required="true" placeholder="Vælg budgetpost*" v-model="budgetPost">
                    <option v-if="!budgetPosts.length" value="" disabled>Indlæser...</option>
                    <option v-else v-for="budget in budgetPosts" :key="budget.id" :value="budget.id"
                            :disabled="budget.contract && budget.contract?.status?.state !== 'ACCEPTED'">
                        {{ budget.title }}&nbsp;&nbsp;({{ formatNumberToPrice(budget.payment.price) }})
                    </option>
                </Select>

                <Input type="text" placeholder="Beskrivelse eller faktura nr.*" :required="true" v-model="fields.description"
                       tooltip="Beskriv udgiftens indhold – f.eks. faktura nr."/>

                <DateSelector placeholder="Bilagsdato*" v-model="fields.created" :required="true"
                              tooltip="Bilagsdato fra kvittering"/>

                <Price placeholder="Beløb, inkl. moms*" :required="true" v-model="fields.price"
                       tooltip="Beløb der er overført"/>

                <Upload label="Vedhæft kvittering" v-model="fields.file"
                        tooltip="Vedhæft bliag til udgiften som f.eks. faktura fra håndværker eller kvittering fra tømmerhandel"/>
            </fieldset>
            <ActionButton icon="check" :float="true"/>
        </Form>

        <Dialog v-if="isConfirmPaymentDialogOpen">
            <template #title>Vær opmærksom</template>
            <template #default>
                <p>Du er ved at registrere en udgift der overstiger det samlede afsat beløb på budgetposten.</p>
                <p>Vil du fortsætte?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Registrér udgift" :small="true" :red="true" @click="create"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmPaymentDialogOpen = false"/>
            </template>
        </Dialog>

        <Dialog v-if="isRejectedPaymentDialogOpen">
            <template #title></template>
            <template #default>
                <p>Du er kan ikke registrere en udgift der overstiger det samlede afsat beløb på denne budgetpost.</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Vis budgetpost" :small="true" :red="true" @click="showBudgetPost"/>
                <ActionButton icon="signout" label="Luk" :small="true" :secondary="true" @click="isRejectedPaymentDialogOpen = false"/>
            </template>
        </Dialog>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Upload from '@/components/inputs/Upload.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Price from '@/components/inputs/Price.vue';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import Select from '@/components/inputs/Select.vue';
import { budgetsCollection, expensesCollection } from '@/utils/collections';
import { ref, onUnmounted } from 'vue';
import methods from '@/utils/methods';
import { useRoute } from 'vue-router';
import { uploadFile } from '@/utils/firebase';
import useState from '@/store';
import Dialog from '@/components/Dialog.vue';
import DateSelector from '@/components/inputs/Date.vue';

export default {
    name: 'Expense',
    mixins: [methods],
    components: {
        Modal,
        Upload,
        ActionButton,
        Price,
        Form,
        Input,
        Select,
        Dialog,
        DateSelector,
    },
    setup() {
        const budgetPosts = ref([]);
        const route = useRoute();
        const { project } = useState();
        const budgetPost = route.params.budgetPost || '';

        const unsubscribe = budgetsCollection().onSnapshot((querySnapshot) => {
            budgetPosts.value = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));
        }, (err) => {
            console.log(err);
        });

        onUnmounted(() => {
            unsubscribe();
        });

        return { project, budgetPost, budgetPosts };
    },
    data() {
        return {
            fields: {
                description: null,
                price: null,
                file: null,
                created: new Date(),
            },
            isConfirmPaymentDialogOpen: false,
            isRejectedPaymentDialogOpen: false,
        };
    },
    methods: {
        showBudgetPost() {
            this.$router.push({ name: 'view-budget', params: { id: this.budgetPost } });
        },
        validatePayment() {
            const budgetPost = this.budgetPosts.find(({ id }) => id === this.budgetPost);
            const totalPaidPrice = budgetPost.payment.paid + this.fields.price;

            if (totalPaidPrice > budgetPost.payment.price) this.isConfirmPaymentDialogOpen = true;
            else this.create();
        },
        async create() {
            try {
                const data = this.removeNullValues(this.$data.fields);

                if (data.file) {
                    const { file } = data;
                    data.file = await uploadFile(file, file.name, this.project.id);
                }

                await expensesCollection(this.budgetPost).add(data);
                await this.$router.push({ name: 'budget' });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
