<template>
    <article id="modal-overlay">
        <section ref="drag" @touchstart.passive="onDrag">
            <button class="close" @click="close"><Icon name="angle"/></button>
            <div ref="scroll" :class="{ 'aside' : true, 'card' : true, 'scroll' : true, 'floating' : containsFloatingButton }">
                <div :class="{ 'content': true, 'expand': expand }">
                    <slot/>
                </div>
                <footer v-if="hasFooterContent">
                    <slot name="footer"/>
                </footer>
            </div>
        </section>
    </article>
</template>

<script>
import Icon from '@/components/Icon.vue';
import useState from '@/store';
import { onUnmounted } from 'vue';

export default {
    name: 'Modal',
    components: {
        Icon,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        containsFloatingButton: {
            type: Boolean,
            default: false,
        },
        expand: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { slots }) {
        const { clearModalData } = useState();
        const hasFooterContent = !!slots.footer;

        onUnmounted(() => {
            clearModalData();
        });

        return { hasFooterContent };
    },
    data() {
        return {
            dragStart: 0,
            dragAllowed: false,
            draggedPixel: 0,
            draggedPercent: 0,
            animationTime: 0,
            backgroundOpacity: 0,
        };
    },
    mounted() {
        this.animationTime = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--time-transition')) * 1000;
        this.backgroundOpacity = parseFloat(getComputedStyle(this.$el).getPropertyValue('--background-opacity'));
    },
    methods: {
        onDrag(e) {
            const { drag, scroll } = this.$refs;
            if (!scroll.scrollTop) {
                this.dragStart = e.touches[0].clientY;
                drag.addEventListener('touchmove', this.onMove, { passive: true });
                drag.addEventListener('touchend', this.onEnd, { passive: true });
            } else {
                drag.removeEventListener('touchmove', this.onMove);
                drag.removeEventListener('touchend', this.onEnd);
            }
        },
        onMove(e) {
            const { drag } = this.$refs;
            if (e.touches[0].clientY > this.dragStart) {
                this.draggedPixel = e.touches[0].clientY - this.dragStart;
                this.draggedPercent = (this.draggedPixel / drag.offsetHeight) * 100;
                drag.style.top = `${this.draggedPixel}px`;
                this.$el.style.background = `rgba(var(--black-color), ${this.backgroundOpacity - (this.draggedPercent / 100) * this.backgroundOpacity}%)`;
            } else {
                drag.removeEventListener('touchmove', this.onMove);
            }
        },
        onEnd() {
            const { drag } = this.$refs;
            if (this.draggedPercent > 25) {
                this.close();
            } else {
                drag.removeEventListener('touchmove', this.onMove);
                drag.removeEventListener('touchend', this.onEnd);
                drag.classList.add('slide-into-place');
                drag.style.top = '0';
                this.$el.style.background = `rgba(var(--black-color), ${this.backgroundOpacity}%)`;
                this.draggedPixel = 0;
                setTimeout(() => drag.classList.remove('slide-into-place'), this.animationTime);
            }
        },
        close() {
            const page = this.$route.matched.slice(-2)[0];
            this.$router.push(page);
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

#modal-overlay {
    --background-opacity: 30%;
    --background-color: var(--black-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--background-color), var(--background-opacity));
    display: flex;
    justify-content: flex-end;
    z-index: 10;
    animation: background-fade-in var(--time-transition) var(--cubic-transition);

    @media @laptop-max-screen {
        flex-direction: column;
    }

    &.modal-leave-active {
        animation: background-fade-out var(--time-transition) var(--cubic-transition);

        section {
            @media @laptop-max-screen {
                animation: section-slide-down var(--time-transition) var(--cubic-transition);
            }

            @media @laptop-screen {
                animation: section-slide-out var(--time-transition) var(--cubic-transition);
            }
        }
    }

    section {
        position: relative;

        @media @laptop-max-screen {
            margin-top: var(--header-height);
            height: calc(100% - var(--header-height));
            animation: section-slide-up var(--time-transition) var(--cubic-transition);

            &::before {
                content: "";
                position: absolute;
                top: -16px;
                left: calc(50% - 25px);
                width: 50px;
                height: 6px;
                background: rgb(var(--semi-light-grey-color));
                border-radius: 3px;
            }
        }

        @media @laptop-screen {
            margin: 20px 0;
            animation: section-slide-in var(--time-transition) var(--cubic-transition);
            //width: 40%;
            min-width: 460px;
        }

        &.slide-into-place {
            transition: top var(--time-transition) var(--cubic-transition);
        }

        button.close {
            display: none;

            @media @laptop-screen {
                position: absolute;
                top: 50px;
                left: -25px;
                z-index: 1;
                background: rgb(var(--light-grey-color));
                transform: rotate(-90deg);
                font-size: 1.2rem;
                width: 50px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                box-shadow: 0 -10px 20px rgba(var(--black-color), 10%);
                transition: background var(--time-transition) var(--cubic-transition);

                &:hover {
                    background: rgb(var(--semi-light-grey-color));
                }
            }
        }

        div.content {
            --column-gap: 16px;
            max-width: var(--fieldset-max-width);

            &.expand {
                max-width: calc(var(--fieldset-max-width) * 2 + var(--column-gap));
            }
        }
    }

    div.scroll {
        background: rgb(var(--white-color));
        box-shadow: 0 -10px 20px rgba(var(--black-color), 10%);

        button.floating {
            bottom: calc(var(--menuitem-height) + var(--margin) + 40px);
        }
    }
}

@keyframes background-fade-in {
    0% {
        background: rgba(var(--background-color), 0%);
    }
    100% {
        background: rgba(var(--background-color), var(--background-opacity));
    }
}

@keyframes background-fade-out {
    100% {
        background: rgba(var(--background-color), 0%);
    }
}

@keyframes section-slide-up {
    0% {
        top: calc(100% - var(--header-height));
    }
    100% {
        top: 0;
    }
}

@keyframes section-slide-down {
    100% {
        top: calc(100% - var(--header-height));
    }
}

@keyframes section-slide-in {
    0% {
        transform: translate3d(100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes section-slide-out {
    100% {
        transform: translate3d(100%, 0, 0);
    }
}
</style>
